import { createAxiosInstance } from "../../../../api/axiosConfig.js";

export const getData = async (url) => {
  try {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
