import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

import CardWithTitle from "../../helpers/CardWithTitle/CardWithTitle.jsx";
import Modal from "../../helpers/Modal/Modal.jsx";
import ReuseForm from "../../helpers/ReuseForm/ReuseForm.jsx";
import {
  GET_PATIENT_PREFERENCES_BY_ID,
  POST_PATIENT_PREFERENCES,
} from "../../../../../api/Service.js";
import { getData, postData } from "../../API/index.js";

import s from "./Preferences.module.css";
import Pencil from "../../assets/images/edit-green-pencil.svg";

const Preferences = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preferencesData, setPreferencesData] = useState([]);
  const [formState, setFormState] = useState({
    language: "",
    religion: "",
    spiritualPractices: "",
    culture: "",
  });

  // MAIN VARIABLES
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const patientId = localStorage.getItem("kinesin-patientId");

  // FETCHING DATA
  const fetchPreferences = async () => {
    try {
      const response = await getData(
        `${tenantVal}${GET_PATIENT_PREFERENCES_BY_ID}${patientId}`
      );
      console.log("Preferences response", response);
      setPreferencesData(response);
      if (response.length > 0) {
        const latestData = response[response.length - 1];
        setFormState({
          language: latestData.mainLanguage || "",
          religion: latestData.religion || "",
          spiritualPractices: latestData.spiritualPractices || "",
          culture: latestData.culture || "",
        });
      }
    } catch (error) {
      toast.error("Failed to fetch preferences data. Please try again.");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPreferences();
  }, []);

  // HANDLERS
  const handleOpenModal = async () => {
    await fetchPreferences();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = {
      id:
        preferencesData.length > 0
          ? preferencesData[preferencesData.length - 1].id
          : null,
      patientId,
      mainLanguage: formState.language,
      religion: formState.religion,
      spiritualPractices: formState.spiritualPractices,
      culture: formState.culture,
    };
    try {
      const response = await postData(
        `${tenantVal}${POST_PATIENT_PREFERENCES}`,
        formData
      );
      console.log("Server Preferences Response:", response);
      toast.success("Preferences data saved successfully.");
      setIsModalOpen(false);
      fetchPreferences();
    } catch (error) {
      toast.error("Failed to save preferences data. Please try again.");
      console.error("There was a problem with the axios operation:", error);
    }
  };

  // Render last arr element for demonstration only, need PUT endpoint for edit
  const preferencesInfo =
    preferencesData.length > 0
      ? preferencesData[preferencesData.length - 1]
      : {};

  const { mainLanguage, religion, spiritualPractices, culture } =
    preferencesInfo;

  const data = [
    {
      label: "Language",
      value: mainLanguage || "No data",
    },
    { label: "Religion", value: religion || "No data" },
    { label: "Spiritual Practices", value: spiritualPractices || "No data" },
    { label: "Culture", value: culture || "No data" },
  ];

  const fields = [
    {
      label: "Language",
      type: "text",
      name: "language",
      placeholder: "Example: English",
      value: formState.language,
    },
    {
      label: "Religion",
      type: "text",
      name: "religion",
      placeholder: "Example: Christian",
      value: formState.religion,
    },
    {
      label: "Spiritual Practices",
      type: "text",
      name: "spiritualPractices",
      placeholder: "Example: Hindu",
      value: formState.spiritualPractices,
    },
    {
      label: "Culture",
      type: "text",
      name: "culture",
      placeholder: "Example: Chinese",
      value: formState.culture,
    },
  ];

  return (
    <>
      <CardWithTitle
        title={"Preferences"}
        buttonTitle="Edit"
        icon={Pencil}
        marginBottom={"22px"}
        className={s.editButton}
        showButton={false}
        onButtonClick={handleOpenModal}
      >
        <ul className={s.listContainer}>
          {data.map((item, index) => (
            <li key={index} className={s.listItemContainer}>
              <p className={s.listItemTitle}>{item.label}:</p>
              <p className={s.listItemDescr}>{item.value}</p>
            </li>
          ))}
        </ul>

        {isModalOpen && (
          <Modal
            width="500px"
            title="Preferences"
            onClose={handleCloseModal}
            onSave={handleSave}
          >
            <ReuseForm
              fields={fields}
              onChange={handleInputChange}
              onSubmit={handleSave}
            />
          </Modal>
        )}
      </CardWithTitle>
    </>
  );
};

export default Preferences;
