// import React, { useState, useEffect, useRef } from "react";
// import consult from "../../assets/images/navbar/consult.svg";
// import details from "../../assets/images/navbar/details.svg";
// import medications from "../../assets/images/navbar/medications.svg";
// import documents from "../../assets/images/navbar/documents.svg";
// import result from "../../assets/images/navbar/results.svg";
// import protocols from "../../assets/images/navbar/protocols.svg";
// import immunizations from "../../assets/images/navbar/immunizations.svg";
// import family from "../../assets/images/navbar/family.svg";
// import communications from "../../assets/images/navbar/communications.svg";
// import payments from "../../assets/images/navbar/payments.svg";
// import styles from "./NavigationBar.module.css";

// const NavigationBar = () => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [hiddenItems, setHiddenItems] = useState([]);
//   const [visibleItems, setVisibleItems] = useState([]);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   const menuItems = [
//     { src: consult, label: "Consult" },
//     { src: medications, label: "Medications" },
//     { src: documents, label: "Documents" },
//     { src: result, label: "Results" },
//     { src: protocols, label: "Protocols" },
//     { src: immunizations, label: "Immunizations" },
//     { src: details, label: "Details" },
//     { src: family, label: "Family" },
//     { src: communications, label: "Communications" },
//     { src: payments, label: "Payments" },
//   ];

//   const allHiddenItems = [
//     { src: consult, label: "Consult" },
//     { src: medications, label: "Medications" },
//     { src: documents, label: "Documents" },
//     { src: result, label: "Results" },
//     { src: protocols, label: "Protocols" },
//     { src: immunizations, label: "Immunizations" },
//     { src: details, label: "Details" },
//     { src: family, label: "Family" },
//     { src: communications, label: "Communications" },
//     { src: payments, label: "Payments" },
//   ];

//   const updateHiddenItems = () => {
//     const screenWidth = window.innerWidth;
//     setWindowWidth(screenWidth);
//     setVisibleItems(menuItems);
//     if (screenWidth <= 1600 && screenWidth > 1200) {
//       setHiddenItems(allHiddenItems.slice(-3));
//       setVisibleItems(menuItems.slice(0, -3));
//     } else if (screenWidth <= 1200 && screenWidth > 600) {
//       setHiddenItems(allHiddenItems.slice(-6));
//       setVisibleItems(menuItems.slice(0, -6));
//     } else if (screenWidth <= 600 && screenWidth > 360) {
//       setHiddenItems(allHiddenItems.slice(0, 8));
//     } else {
//       setHiddenItems([]);
//     }
//   };

//   useEffect(() => {
//     updateHiddenItems();
//     window.addEventListener("resize", updateHiddenItems);

//     return () => {
//       window.removeEventListener("resize", updateHiddenItems);
//     };
//   }, []);

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   return (
//     <div className={styles.navbar}>
//       <div className={styles.navLinks}>
//         {visibleItems.map((item, index) => (
//           <a key={index} href="#" className={styles.navLink}>
//             <img className={styles.navIcon} src={item.src} alt={item.label} />
//             <span>{item.label}</span>
//           </a>
//         ))}
//         {allHiddenItems
//           .slice(
//             0,
//             windowWidth > 1600
//               ? 0
//               : windowWidth > 1080
//               ? 3
//               : windowWidth > 600
//               ? 6
//               : 8
//           )
//           .map((item, index) => (
//             <a key={index} href="#" className={styles.navLink}>
//               <img className={styles.navIcon} src={item.src} alt={item.label} />
//               <span>{item.label}</span>
//             </a>
//           ))}
//         {hiddenItems.length > 0 && (
//           <div className={styles.moreButtonWrapper}>
//             <button className={styles.moreButton} onClick={toggleDropdown}>
//               ⋮
//             </button>
//             {isDropdownOpen && (
//               <div className={styles.dropdownMenu}>
//                 {hiddenItems.map((item, index) => (
//                   <a key={index} href="#" className={styles.dropdownItem}>
//                     <img
//                       className={styles.navIcon}
//                       src={item.src}
//                       alt={item.label}
//                     />
//                     <span>{item.label}</span>
//                   </a>
//                 ))}
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default NavigationBar;

import React, { useState, useEffect } from "react";
import consult from "../../assets/images/navbar/consult.svg";
import details from "../../assets/images/navbar/details.svg";
import medications from "../../assets/images/navbar/medications.svg";
import documents from "../../assets/images/navbar/documents.svg";
import result from "../../assets/images/navbar/results.svg";
import protocols from "../../assets/images/navbar/protocols.svg";
import immunizations from "../../assets/images/navbar/immunizations.svg";
import family from "../../assets/images/navbar/family.svg";
import communications from "../../assets/images/navbar/communications.svg";
import payments from "../../assets/images/navbar/payments.svg";
import styles from "./NavigationBar.module.css";

const NavigationBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);

  const menuItems = [
    { src: consult, label: "Consult" },
    { src: medications, label: "Medications" },
    { src: documents, label: "Documents" },
    { src: result, label: "Results" },
    { src: protocols, label: "Protocols" },
    { src: immunizations, label: "Immunizations" },
    { src: details, label: "Details" },
    { src: family, label: "Family" },
    { src: communications, label: "Communications" },
    { src: payments, label: "Payments" },
  ];

  const updateHiddenItems = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 1600 && screenWidth > 1200) {
      setHiddenItems(menuItems.slice(-3));
      setVisibleItems(menuItems.slice(0, -3));
    } else if (screenWidth <= 1200 && screenWidth > 700) {
      setHiddenItems(menuItems.slice(-6));
      setVisibleItems(menuItems.slice(0, -6));
    } else if (screenWidth <= 700 && screenWidth > 360) {
      setHiddenItems(menuItems.slice(-8));
      setVisibleItems(menuItems.slice(0, -8));
    } else {
      setHiddenItems([]);
      setVisibleItems(menuItems);
    }
  };

  useEffect(() => {
    updateHiddenItems();
    window.addEventListener("resize", updateHiddenItems);

    return () => {
      window.removeEventListener("resize", updateHiddenItems);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.navLinks}>
        {visibleItems.map((item, index) => (
          <a key={index} href="#" className={styles.navLink}>
            <img className={styles.navIcon} src={item.src} alt={item.label} />
            <span>{item.label}</span>
          </a>
        ))}
        {hiddenItems.length > 0 && (
          <div className={styles.moreButtonWrapper}>
            <button className={styles.moreButton} onClick={toggleDropdown}>
              ⋮
            </button>
            {isDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {hiddenItems.map((item, index) => (
                  <a key={index} href="#" className={styles.dropdownItem}>
                    <img
                      className={styles.navIcon}
                      src={item.src}
                      alt={item.label}
                    />
                    <span>{item.label}</span>
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
