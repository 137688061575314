import React, { useState, useEffect } from "react";
import s from "./EditModalForm.module.css";
import Select from "../../helpers/Select/Select.jsx";
import CustomInput from "../../helpers/CustomInput/CustomInput.jsx";
import CustomDatepicker from "../../helpers/CustomDatepicker.jsx";
import { useSelector } from "react-redux";
import { PATIENT_CONTROLER_URL_ID } from "../../../../../api/Service.js";
import { createAxiosInstance } from "../../../../../api/axiosConfig.js";

const EditModalForm = ({
  patient,
  handleInputChange,
  handleInputAddressChange,
}) => {
  return (
    <div>
      <form className={s.formContainer}>
        <div className={s.topContainer}>
          <h3 className={s.subTitle}>Personal information</h3>
          <div className={s.formTopContainer}>
            <div className={s.leftContainer}>
              <div className={s.inputWrapper}>
                <label>Title</label>
                <Select
                  options={["Mr.", "Mrs.", "Miss", "Dr."]}
                  value={patient.title || ""}
                  onChange={(value) => handleInputChange("title", value)}
                />
              </div>
              <div className={s.inputWrapper}>
                <label>First name</label>
                <CustomInput
                  type="text"
                  value={patient.firstName || ""}
                  onChange={(e) =>
                    handleInputChange("firstName", e.target.value)
                  }
                />
              </div>
              <div className={s.inputWrapper}>
                <label>Sex</label>
                <Select
                  options={["Male", "Female"]}
                  value={patient.gender || ""}
                  onChange={(value) => handleInputChange("gender", value)}
                />
              </div>
              <div className={s.inputWrapper}>
                <label>Email</label>
                <CustomInput
                  type="email"
                  value={patient.email || ""}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              </div>
            </div>
            <div className={s.rightContainer}>
              <div className={s.inputWrapper}>
                <label>DOB</label>
                <CustomDatepicker
                  value={patient.dateOfBirth || ""}
                  onChange={(date) => handleInputChange("dateOfBirth", date)}
                />
              </div>
              <div className={s.inputWrapper}>
                <label>Last name</label>
                <CustomInput
                  type="text"
                  value={patient.surname || ""}
                  onChange={(e) => handleInputChange("surname", e.target.value)}
                />
              </div>
              <div className={s.inputWrapper}>
                <label>PPS Number</label>
                <CustomInput
                  type="text"
                  value={patient.nationalNumber || ""}
                  onChange={(e) =>
                    handleInputChange("nationalNumber", e.target.value)
                  }
                />
              </div>
              <div className={s.inputWrapper}>
                <label>Mobile number</label>
                <CustomInput
                  type="text"
                  value={patient.mobilePhone || ""}
                  onChange={(e) =>
                    handleInputChange("mobilePhone", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={s.bottomContainer}>
          <h3 className={s.subTitle}>Address</h3>
          <div className={s.formTopContainer}>
            <div className={s.leftContainer}>
              <div className={s.inputWrapper}>
                <label>Address Line 1</label>
                <CustomInput
                  type="text"
                  value={patient.address.addressLine1 || ""}
                  onChange={(e) =>
                    handleInputAddressChange("addressLine1", e.target.value)
                  }
                />
              </div>
              <div className={s.inputWrapper}>
                <label>Address Line 3</label>
                <CustomInput
                  type="text"
                  value={patient.address.addressLine3 || ""}
                  onChange={(e) =>
                    handleInputAddressChange("addressLine3", e.target.value)
                  }
                />
              </div>
              <div className={s.inputWrapper}>
                <label>County</label>
                <CustomInput
                  type="text"
                  value={patient.address.county || ""}
                  onChange={(e) =>
                    handleInputAddressChange("county", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={s.rightContainer}>
              <div className={s.inputWrapper}>
                <label>Address Line 2</label>
                <CustomInput
                  type="text"
                  value={patient.address.addressLine2 || ""}
                  onChange={(e) =>
                    handleInputAddressChange("addressLine2", e.target.value)
                  }
                />
              </div>
              <div className={s.inputWrapper}>
                <label>City</label>
                <CustomInput
                  type="text"
                  value={patient.address.city || ""}
                  onChange={(e) =>
                    handleInputAddressChange("city", e.target.value)
                  }
                />
              </div>
              <div className={s.inputWrapper}>
                <label>Post Code</label>
                <CustomInput
                  type="text"
                  value={patient.address.postCode || ""}
                  onChange={(e) =>
                    handleInputAddressChange("postCode", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditModalForm;
