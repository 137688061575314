import React, { useState } from "react";
import s from "./AllergiesIntolerance.module.css";
import TransparentButton from "../../helpers/TransparentButton/TransparentButton.jsx";
import Modal from "../../helpers/Modal/Modal.jsx";
import Select from "../../helpers/Select/Select.jsx";

import stopButton from "../../assets/images/stopButton.svg";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { createAxiosInstance } from "../../../../../api/axiosConfig.js";
import Allergies from "./Allergies/Allergies.jsx";
import Intolerance from "./Intolerance/Intolerance.jsx";

const AlergiesIntolerance = () => {
  return (
    <div className={s.cardWrapper}>
      <Allergies />
      <Intolerance />
    </div>
  );
};

export default AlergiesIntolerance;
