import { createAxiosInstance } from "../../../../api/axiosConfig.js";

export const postData = async (url, data) => {
  try {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
