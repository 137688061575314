import React from "react";

import Trash from "../../assets/images/trash-grey-icon.svg";
import redTrash from "../../assets/images/red-trash.svg";
import editPencil from "../../assets/images/gray-edit-pencil.svg";

import s from "./Table.module.css";

const Table = ({
  data = [],
  columns = [],
  width,
  paddingContainer,
  showAddButton = false,
  showDeleteButton = false,
  showStopButton = true,
  showCheckImg = false,
  onDelete,
  onEdit,
  columnStyles = {},
}) => {
  console.log(columnStyles, "columnStyles");
  const formatDateArray = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length === 0) return "";
    const [year, month, day] = dateArray;
    const formattedYear = year.toString().slice(-2);
    return `${day}/${month}/${formattedYear}`;
  };

  return (
    <div
      style={paddingContainer && { padding: paddingContainer }}
      className={s.tableContainer}
    >
      <table className={s.table}>
        <thead className={s.thead}>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className={s.th}>
                {column.header}
              </th>
            ))}
            <th className={s.th}></th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((entry) => (
              <tr key={entry.id}>
                {columns.map((column, colIndex) => (
                  <td
                    title={entry[column.field]}
                    key={colIndex}
                    className={s.tdName}
                    style={{
                      ...columnStyles[column.field],
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                    }}
                  >
                    {entry[column.field] !== undefined
                      ? column.field === "dateCommenced" &&
                        Array.isArray(entry[column.field])
                        ? formatDateArray(entry[column.field])
                        : entry[column.field]
                      : "-"}
                  </td>
                ))}
                <td className={s.actions}>
                  <div className={s.hiddenButtons}>
                    <button
                      className={s.stopButton}
                      onClick={() => onEdit(entry.id)}
                    >
                      <img
                        className={s.stopIcon}
                        src={editPencil}
                        alt="edit button"
                      />
                    </button>
                    <button
                      className={s.stopButton}
                      onClick={() => onDelete(entry.id)}
                    >
                      <img
                        className={s.stopIcon}
                        src={redTrash}
                        alt="stop button"
                      />
                    </button>
                  </div>

                  {showAddButton && (
                    <button className={s.addButton}>
                      <p>Add</p>
                    </button>
                  )}
                  {showDeleteButton && (
                    <button
                      className={s.deleteButton}
                      onClick={() => onDelete(entry.id)}
                    >
                      <img
                        className={s.deleteIcon}
                        src={Trash}
                        alt="delete icon"
                      />
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + 1} className={s.noData}>
                No data available, please add new
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
