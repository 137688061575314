import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

import CardWithTitle from "../../helpers/CardWithTitle/CardWithTitle.jsx";
import Modal from "../../helpers/Modal/Modal.jsx";
import ReuseForm from "../../helpers/ReuseForm/ReuseForm.jsx";
import {
  GET_PATIENT_SOCIAL_BY_ID,
  POST_PATIENT_SOCIAL,
} from "../../../../../api/Service.js";
import { getData, postData } from "../../API/index.js";

import s from "./Social.module.css";
import Pencil from "../../assets/images/edit-green-pencil.svg";

const Social = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const [formState, setFormState] = useState({
    smoking: "",
    home: "",
    occupation: "",
    alcohol: "",
  });
  console.log("test", socialData);

  // MAIN VARIABLES
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const patientId = localStorage.getItem("kinesin-patientId");

  // FETCHING DATA
  const fetchSocial = async () => {
    try {
      const response = await getData(
        `${tenantVal}${GET_PATIENT_SOCIAL_BY_ID}${patientId}`
      );
      console.log("Social response", response);
      setSocialData(response);
      if (response.length > 0) {
        const latestData = response[response.length - 1];
        setFormState({
          smoking: latestData.exSmokerYears || "",
          home: latestData.homeLife || "",
          occupation: latestData.occupation || "",
          alcohol: latestData.alcoholUnitsPerWeek || "",
        });
      }
    } catch (error) {
      toast.error("Failed to fetch social data. Please try again.");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSocial();
  }, []);

  // HANDLERS
  const handleOpenModal = async () => {
    await fetchSocial();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = {
      id: socialData.length > 0 ? socialData[socialData.length - 1].id : null,
      patientId,
      exSmokerYears: formState.smoking,
      homeLife: formState.home,
      occupation: formState.occupation,
      alcoholUnitsPerWeek: formState.alcohol,
    };
    try {
      const response = await postData(
        `${tenantVal}${POST_PATIENT_SOCIAL}`,
        formData
      );
      console.log("Server Social Response:", response);
      toast.success("Social data saved successfully.");
      setIsModalOpen(false);
      fetchSocial();
    } catch (error) {
      toast.error("Failed to save social data. Please try again.");
      console.error("There was a problem with the axios operation:", error);
    }
  };

  //render last arr element for demonstration only, need PUT endpoint for edit
  const socialInfo =
    socialData.length > 0 ? socialData[socialData.length - 1] : {};

  const { exSmokerYears, homeLife, occupation, alcoholUnitsPerWeek } =
    socialInfo;

  const data = [
    {
      label: "Smoking",
      value: exSmokerYears > 0 ? `${exSmokerYears} PY` : "Not Smoking",
    },
    { label: "Home", value: homeLife || "" },
    { label: "Occupation", value: occupation || "" },
    { label: "Alcohol", value: alcoholUnitsPerWeek || "" },
  ];

  const fields = [
    {
      label: "Smoking",
      type: "number",
      name: "smoking",
      placeholder: "Enter number of years",
      value: formState.smoking,
    },
    {
      label: "Home",
      type: "text",
      name: "home",
      placeholder: "Example: Lives with son",
      value: formState.home,
    },
    {
      label: "Occupation",
      type: "text",
      name: "occupation",
      placeholder: "Example: Retired teacher",
      value: formState.occupation,
    },
    {
      label: "Alcohol",
      type: "number",
      name: "alcohol",
      placeholder: "Example: 13",
      value: formState.alcohol,
    },
  ];

  return (
    <>
      <CardWithTitle
        title={"Social"}
        buttonTitle="Edit"
        icon={Pencil}
        marginBottom={"22px"}
        className={s.editButton}
        showButton={false}
        onButtonClick={handleOpenModal}
      >
        <ul className={s.listContainer}>
          {data.map((item, index) => (
            <li key={index} className={s.listItemContainer}>
              <p className={s.listItemTitle}>{item.label}:</p>
              <p className={s.listItemDescr}>{item.value}</p>
            </li>
          ))}
        </ul>

        {isModalOpen && (
          <Modal
            width="500px"
            title="Social"
            onClose={handleCloseModal}
            onSave={handleSave}
          >
            <ReuseForm
              fields={fields}
              onChange={handleInputChange}
              onSubmit={handleSave}
            />
          </Modal>
        )}
      </CardWithTitle>
    </>
  );
};

export default Social;
