import React from "react";
import s from "./CustomCheckbox.module.css";

const CustomCheckbox = ({ handleChange, checked }) => {
  console.log(checked);
  return (
    <div className={s.checkboxWrapper}>
      <input
        className={s.checkbox}
        type="checkbox"
        checked={checked || false}
        onChange={handleChange}
      />
      <span className={s.customCheckbox}></span>
    </div>
  );
};

export default CustomCheckbox;
